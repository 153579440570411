import { useStaticQuery, graphql } from 'gatsby'

export const useProjects = () => {
	const data = useStaticQuery(
		graphql`
			query {
				allMarkdownRemark(
					filter: { fileAbsolutePath: { regex: "/(projects)/" } }
					sort: { fields: [frontmatter___date], order: DESC }
				) {
					edges {
						node {
							fields {
								slug
							}
							id
							frontmatter {
								thumbnail_1
								thumbnail_2
								thumbnail_3
								title
								excerpt
								subtitle
								description
								tasks
								technologies
								year
							}
							html
						}
					}
				}
			}
		`
	)
	return data.allMarkdownRemark.edges.map(edge => {
		return edge.node
	})
}
