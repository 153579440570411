import React from 'react'
import styled from 'styled-components'
import { rhythm } from '../utils/typography'
import Layout from '../components/layout'
import Home from '../pageSections/home'
import About from '../pageSections/about'
import Projects from '../pageSections/projects'

export default function Index() {
	return (
		<Layout>
			<HomeSection id="home">
				<Home />
			</HomeSection>
			<AboutSection id="about">
				<About />
			</AboutSection>
			<ProjectsSection id="projects">
				<Projects />
			</ProjectsSection>
		</Layout>
	)
}

const HomeSection = styled.div`
	padding-top: ${rhythm(5)};
	height: ${rhythm(35)};

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		padding-top: ${rhythm(3)};
		height: ${rhythm(30)};
	}

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		padding-top: ${rhythm(3)};
		height: ${rhythm(20)};
	}
`

const AboutSection = styled.div`
	height: ${rhythm(35)};
	padding-top: ${rhythm(5)};

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		padding-top: ${rhythm(3)};
		height: ${rhythm(53)};
	}

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		padding-top: ${rhythm(3)};
		height: ${rhythm(45)};
	}
`

const ProjectsSection = styled.div`
	padding-top: ${rhythm(5)};

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		padding-top: ${rhythm(3)};
	}
`
