import React from 'react'
import styled from 'styled-components'
import { rhythm } from '../utils/typography'
import BigTitle from '../components/bigTitle'
import Rect from '../components/rect'
import NavMenu from '../components/navMenu'
import CustomButton from '../components/customButton'
import MediumParagraph from '../components/mediumParagraph'
import Socials from '../components/socials'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { useHome } from '../hooks/useHome'
import ReactMarkdown from 'react-markdown'

export default function Home() {
	const homeData = useHome()

	return (
		<>
			<MainRect />
			<LeftSide>
				<NameWrapper>
					<FirstName>Brandon</FirstName> <LastName>Yuen</LastName>
				</NameWrapper>
				<ButtonsWrapper>
					<CustomButton variant="primary" text="More about me" onClick={() => scrollTo('#about')} />
					<a href={homeData.frontmatter.resume_pdf} download>
						<CustomButton variant="secondary" text="Download resume" />
					</a>
				</ButtonsWrapper>
				<IntroductionWrapper>
					<Profession>{homeData.frontmatter.prof_title}</Profession>
					<ProfPara>
						<ReactMarkdown>{homeData.frontmatter.prof_intro}</ReactMarkdown>
					</ProfPara>
				</IntroductionWrapper>
				<SocialsWrapper>
					<Socials></Socials>
				</SocialsWrapper>
			</LeftSide>
			<RightSide>
				<NavWrapper>
					<NavMenu />
				</NavWrapper>
			</RightSide>
		</>
	)
}

const MainRect = styled(Rect)`
	position: absolute;
	top: -30rem;
	margin-left: 19.5rem;
	width: 100rem;
	height: 60rem;
	transform: rotate(-23deg);

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		margin-left: 19rem;
	}
	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		margin-left: 61vw;
	}
	@media (min-width: 1920px) {
		width: 150rem;
		top: -35rem;
	}
`

const LeftSide = styled.div`
	float: left;
`
const RightSide = styled.div`
	float: right;
`

const NameWrapper = styled.div`
	position: absolute;
`

const FirstName = styled(BigTitle).attrs({
	'data-sal': 'slide-right',
	style: {
		'--sal-delay': '200ms',
		'--sal-duration': '700ms'
	}
})`
	color: ${props => props.theme.colors.secondary};

	::after {
		content: '';
		display: block;
		margin-top: ${rhythm(-0.5)};
		height: ${rhythm(0.3)};
		width: 100%;
		background-color: ${props => props.theme.colors.secondary};
		transform-origin: left top;
		transform: scale(1, 1);
		transition: color 0.1s, transform 0.2s ease-out;

		@media (max-width: ${props => props.theme.sizes.minMobile}) {
			margin-top: ${rhythm(-0.4)};
			height: ${rhythm(0.2)};
		}
	}
`

const LastName = styled(BigTitle).attrs({
	'data-sal': 'slide-left',
	style: {
		'--sal-delay': '200ms',
		'--sal-duration': '700ms'
	}
})`
	color: ${props => props.theme.colors.primary};
	margin-left: ${rhythm(1)};

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		margin-left: 4vw;
	}
`

const NavWrapper = styled.div.attrs({
	'data-sal': 'slide-left',
	style: {
		'--sal-delay': '1000ms',
		'--sal-duration': '700ms'
	}
})`
	margin-top: ${rhythm(1.2)};
	right: 0;

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		visibility: hidden;
	}

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		position: absolute;
		margin-top: 0;
		top: ${rhythm(1)};
		right: ${rhythm(1)};
	}
`

const ButtonsWrapper = styled.div.attrs({
	'data-sal': 'slide-right',
	style: {
		'--sal-delay': '1000ms',
		'--sal-duration': '700ms'
	}
})`
	position: absolute;
	display: flex;
	flex-direction: row;
	margin-top: ${rhythm(10)};

	button {
		margin-right: ${rhythm(1)};
	}

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		position: relative;
		margin-top: ${rhythm(5)};
		button {
			margin-right: ${rhythm(0.5)};
		}
	}
	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		margin-top: ${rhythm(4)};
		flex-direction: column;
		max-width: 53vw;
		align-items: center;
		button {
			font-size: 4vw;
			margin-right: unset;
			margin-bottom: ${rhythm(0.1)};
		}
	}
`

const IntroductionWrapper = styled.div.attrs({
	'data-sal': 'slide-left',
	style: {
		'--sal-delay': '700ms',
		'--sal-duration': '1000ms',
		'--sal-easing': 'ease'
	}
})`
	position: absolute;
	margin-top: ${rhythm(9.5)};
	margin-left: ${rhythm(20)};
	padding-right: ${rhythm(1)};

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		position: relative;
		margin-top: ${rhythm(2)};
		margin-left: 0;
		padding-right: 0;
	}
`

const Profession = styled(BigTitle)`
	color: ${props => props.theme.colors.primary};
	font-size: ${rhythm(1.2)};

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		color: ${props => props.theme.colors.secondary};
	}
	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		font-size: 6vw;
		line-height: 6vw;
	}
`
const ProfPara = styled(MediumParagraph)`
	color: ${props => props.theme.colors.primary};
	max-width: ${rhythm(19)};

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		color: ${props => props.theme.colors.secondary};
		max-width: ${rhythm(14)};
	}
	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		font-size: 4vw;
		line-height: 6vw;
		padding-right: 7vw;
	}
`

const SocialsWrapper = styled.div.attrs({
	'data-sal': 'slide-right',
	style: {
		'--sal-delay': '1000ms',
		'--sal-duration': '700ms'
	}
})`
	position: absolute;
	margin-top: ${rhythm(20)};
	font-size: ${rhythm(1.5)};

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		position: relative;
		margin-top: ${rhythm(5)};
		font-size: ${rhythm(1.5)};
	}
	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		margin-top: ${rhythm(1)};
		font-size: ${rhythm(1.2)};
	}
`
