import React from 'react'
import styled from 'styled-components'
import { rhythm } from '../utils/typography'
import { lighten } from 'polished'
import { FaAngleRight } from 'react-icons/fa'

export default function CustomButton({ variant, text, arrow, onClick }) {
	switch (variant) {
		case 'secondary':
			return (
				<SecondaryButton onClick={onClick}>
					{text}
					{arrow && <FaAngleRight />}
				</SecondaryButton>
			)
		case 'tertiary':
			return (
				<TertiaryButton onClick={onClick}>
					{text}
					{arrow && <FaAngleRight />}
				</TertiaryButton>
			)
		default:
			return <PrimaryButton onClick={onClick}>{text}</PrimaryButton>
	}
}

const Btn = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: ${rhythm(0.1)} ${rhythm(0.7)};
	border-radius: 5px;
	font-family: ${props => props.theme.fonts.medium};
	border: none;
	background: none;
`

const PrimaryButton = styled(Btn)`
	transition: all 0.1s linear;
	background-color: ${props => props.theme.colors.secondary};
	color: ${props => props.theme.colors.primary};

	:hover {
		background-color: ${props => lighten(0.2, props.theme.colors.secondary)};
		color: $brand-primary !important;
		outline: none !important;
	}
`

const SecondaryButton = styled(Btn)`
	color: ${props => props.theme.colors.secondary};
	:hover {
		text-decoration: underline;
	}
`

const TertiaryButton = styled(Btn)`
	padding: 0;
	justify-content: space-between;
	font-family: ${props => props.theme.fonts.big};
	color: ${props => lighten(0.4, props.theme.colors.secondary)};
	:hover {
		text-decoration: underline;
	}
`
