import { useStaticQuery, graphql } from 'gatsby'

export const useHome = () => {
	const data = useStaticQuery(
		graphql`
			query {
				markdownRemark(fileAbsolutePath: { regex: "/(home)/" }) {
					id
					fields {
						slug
					}
					frontmatter {
						resume_pdf
						prof_title
						prof_intro
						about_desc
						about_quote
						socials {
							email
							linkedin_url
							github_url
						}
					}
				}
			}
		`
	)
	return data.markdownRemark
}
