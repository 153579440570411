import React from 'react'
import styled from 'styled-components'
import { rhythm } from '../utils/typography'
import scrollTo from 'gatsby-plugin-smoothscroll'

export default function NavMenu() {
	return (
		<NavUl>
			<NavLi>
				<ScrollLink className="active">Home</ScrollLink>
				<ScrollLink onClick={() => scrollTo('#about')}>About me</ScrollLink>
				<ScrollLink onClick={() => scrollTo('#projects')}>Projects</ScrollLink>
			</NavLi>
		</NavUl>
	)
}

const NavUl = styled.ul`
	margin: 0;
	display: flex;
`

const NavLi = styled.li`
	margin: 0;
	display: flex;
	align-items: center;
	list-style-type: none;
`

const ScrollLink = styled.a`
	position: relative;
	font-family: ${props => props.theme.fonts.medium};
	margin-right: ${rhythm(0.8)};
	color: ${props => props.theme.colors.primary};
	cursor: pointer;

	::after {
		content: '';
		display: block;
		position: absolute;
		height: ${rhythm(0.08)};
		width: 100%;
		background-color: ${props => props.theme.colors.primary};
		transform-origin: right top;
		transform: scale(0, 1);
		transition: color 0.1s, transform 0.2s ease-out;
	}

	:hover::after,
	:focus::after,
	&.active::after {
		content: '';
		display: block;
		position: absolute;
		height: ${rhythm(0.08)};
		width: 100%;
		background-color: ${props => props.theme.colors.primary};
		transform-origin: left top;
		transform: scale(1, 1);
		transition: color 0.1s, transform 0.2s ease-out;
	}
`
