import React, { useState } from 'react'
import styled from 'styled-components'
import { rhythm } from '../utils/typography'
import Rect from '../components/rect'
import BigTitle from '../components/bigTitle'
import MediumParagraph from '../components/mediumParagraph'
import { FaChevronDown } from 'react-icons/fa'
import ScrollTrigger from 'react-scroll-trigger'
import { useHome } from '../hooks/useHome'
import ReactMarkdown from 'react-markdown'
import scrollTo from 'gatsby-plugin-smoothscroll'

export default function About() {
	const [scrolledToProjects, setScrolledToProjects] = useState(false)
	const homeData = useHome()

	const onEnterViewport = () => {
		setScrolledToProjects(false)
	}

	const onExitViewport = () => {
		setScrolledToProjects(true)
	}

	return (
		<>
			<Prefix>Hi,</Prefix>
			<Name>I&apos;m Brandon.</Name>
			<DescPara>
				<ReactMarkdown>{homeData.frontmatter.about_desc}</ReactMarkdown>
			</DescPara>
			<QuoteBrandonWrapper scrolledToProjects={scrolledToProjects}>
				<ScrollTrigger onEnter={onEnterViewport} onExit={onExitViewport}>
					<QuotePara>
						<QuoteMarkdown>{homeData.frontmatter.about_quote}</QuoteMarkdown>
					</QuotePara>
				</ScrollTrigger>
				<Brandon src="/assets/hk_pf_greyscale.png" />
			</QuoteBrandonWrapper>
			<BottomRect
				scrolledToProjects={scrolledToProjects}
				onClick={() => (scrolledToProjects ? scrollTo('#about') : scrollTo('#projects'))}
			>
				<KeepScrollingLabel>
					<ScrollingPara scrolledToProjects={scrolledToProjects}>
						{scrolledToProjects ? 'More about me' : 'Keep scrolling to see projects'}
					</ScrollingPara>{' '}
					<AnimatedIcon>
						<FaChevronDown />
					</AnimatedIcon>
				</KeepScrollingLabel>
			</BottomRect>
		</>
	)
}

const Prefix = styled(BigTitle).attrs({
	'data-sal': 'flip-up',
	style: {
		'--sal-delay': '200ms',
		'--sal-duration': '500ms',
		'--sal-easing': 'ease'
	}
})`
	display: block;
	text-transform: unset;

	::after {
		content: '';
		position: absolute;
		display: inline-block;
		margin-top: ${rhythm(2)};
		margin-left: ${rhythm(1)};
		height: ${rhythm(0.3)};
		width: ${rhythm(10)};
		background-color: ${props => props.theme.colors.secondary};

		@media (max-width: ${props => props.theme.sizes.minMobile}) {
			margin-top: 7vw;
			height: 2vw;
			width: 40vw;
		}
	}

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		font-size: 12vw;
		line-height: 15vw;
	}
`

const Name = styled(BigTitle).attrs({
	'data-sal': 'flip-up',
	style: {
		'--sal-delay': '500ms',
		'--sal-duration': '750ms',
		'--sal-easing': 'ease'
	}
})`
	text-transform: unset;
	display: block;
	margin-top: -${rhythm(1)};

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		margin-top: -${rhythm(0.5)};
		font-size: 12vw;
		line-height: 18vw;
	}
`

const DescPara = styled(MediumParagraph).attrs({
	'data-sal': 'slide-up',
	style: {
		'--sal-delay': '1200ms',
		'--sal-duration': '1000ms',
		'--sal-easing': 'ease-in-circ'
	}
})`
	max-width: ${rhythm(20)};

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		font-size: 4vw;
		line-height: 6vw;
	}
`

const BottomRect = styled(Rect)`
	position: absolute;
	cursor: pointer;
	z-index: 100;
	width: 130rem;
	height: 50rem;
	transform: rotate(8deg);
	margin-top: ${props => (props.scrolledToProjects ? '-30rem' : '15rem')};
	margin-left: ${props => (props.scrolledToProjects ? '-10rem' : '-10rem')};
	transform: ${props => (props.scrolledToProjects ? 'rotateX(-180deg) rotate(8deg)' : '0')};
	transition: all 1s ease-in-out;

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		margin-top: ${props => (props.scrolledToProjects ? '5rem' : '50rem')};
		margin-left: ${props => (props.scrolledToProjects ? '-4rem' : '-4rem')};
	}

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		margin-top: ${props => (props.scrolledToProjects ? '-10rem' : '35rem')};
		margin-left: ${props => (props.scrolledToProjects ? '-4rem' : '-4rem')};
	}

	@media (min-width: 1920px) {
		width: 180rem;
		margin-top: ${props => (props.scrolledToProjects ? '-30rem' : '12rem')};
		margin-left: ${props => (props.scrolledToProjects ? '-15rem' : '-15rem')};
	}
`

const KeepScrollingLabel = styled.div`
	display: flex;
	align-items: center;
	color: ${props => props.theme.colors.primary};
	padding-top: ${rhythm(0.5)};
	padding-left: ${rhythm(1)};
`
const ScrollingPara = styled(MediumParagraph)`
	display: flex;
	align-items: center;
	color: ${props => props.theme.colors.primary};
	padding-right: ${rhythm(0.5)};
	margin: 0;
	height: ${rhythm(1.5)};
	transform: ${props => (props.scrolledToProjects ? 'rotate(-180deg) scale(-1, 1);' : '0')};

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		font-size: 5vw;
		line-height: 6vw;
	}
`

const AnimatedIcon = styled.span`
	animation: MoveUpDown 1s linear infinite;

	@keyframes MoveUpDown {
		0%,
		100% {
			margin-top: 0;
		}
		50% {
			margin-top: ${rhythm(0.5)};
		}
	}
`

const QuoteBrandonWrapper = styled.div`
	position: absolute;
	height: 40rem;
	margin-top: -20rem;
	z-index: -100;
	right: ${props => (props.scrolledToProjects ? '-500px' : '0px')};
	opacity: ${props => (props.scrolledToProjects ? '0' : '1')};
	transition: all 0.7s ease-in-out;
	transition-delay: ${props => (props.scrolledToProjects ? '0s' : '500ms')};

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		margin-top: ${rhythm(5)};
	}

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		font-size: 5vw;
		line-height: 6vw;
		width: 90vw;
	}
`

const Brandon = styled.img`
	margin: 0;
`

const QuotePara = styled(MediumParagraph).attrs({
	'data-sal': 'slide-left',
	style: {
		'--sal-delay': '1000ms',
		'--sal-duration': '1000ms',
		'--sal-easing': 'ease-in-circ'
	}
})`
	position: absolute;
	width: 20rem;
	margin-top: 10rem;
	margin-left: -15rem;
	font-size: ${rhythm(0.7)};
	font-style: italic;
	line-height: ${rhythm(0.9)};

	@media (max-width: ${props => props.theme.sizes.minDesktopLarge}) {
		margin-left: 0;
		margin-top: -7rem;
	}

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		margin-left: 0rem;
		font-size: 5vw;
		line-height: 6vw;
		width: 100%;
	}
`
const QuoteMarkdown = styled(ReactMarkdown)`
	display: inline-block;
`
