import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { rhythm } from '../utils/typography'
import BigTitle from '../components/bigTitle'
import Title from '../components/title'
import { lighten } from 'polished'
import SmallParagraph from '../components/smallParagraph'
import Subtitle from '../components/subTitle'
import CustomButton from '../components/customButton'
import { useProjects } from '../hooks/useProjects'
import { Link } from 'gatsby'
// import { FaAngleRight, FaAngleLeft } from 'react-icons/fa'
// import Rect from '../components/rect'

export default function Projects() {
	const projects = useProjects()

	return (
		<>
			{/* <PreviousRect>
				<PreviousLabel>
					<AnimatedIconLeft>
						<FaAngleLeft />
					</AnimatedIconLeft>
					PAGE 0
				</PreviousLabel>
			</PreviousRect>
			<NextRect>
				<NextLabel>
					PAGE 2
					<AnimatedIconRight>
						<FaAngleRight />
					</AnimatedIconRight>
				</NextLabel>
			</NextRect> */}
			<ProjectsWrapper>
				{projects.map((project, index) => (
					<Project key={project.id}>
						<ProjectPreview>
							<ContentBlock>
								<ProjectType>Project</ProjectType>
								<ProjectTitle>{project.frontmatter.title}</ProjectTitle>
								<Subtitle>{project.frontmatter.subtitle}</Subtitle>
								<SmallParagraph>
									<ReactMarkdown>{project.frontmatter.excerpt}</ReactMarkdown>
								</SmallParagraph>
								<Link to={`project${project.fields.slug}`}>
									<CustomButton variant="tertiary" text="EXPLORE" arrow={true} />
								</Link>
							</ContentBlock>
							{project.frontmatter.thumbnail_1 && project.frontmatter.thumbnail_2 ? (
								<Link to={`project${project.fields.slug}`}>
									<TripleImagesBlock>
										<MobileImage src={project.frontmatter.thumbnail_1} />
										<MobileImage src={project.frontmatter.thumbnail_2} />
										{project.frontmatter.thumbnail_3 && <MobileImage src={project.frontmatter.thumbnail_3} />}
									</TripleImagesBlock>
								</Link>
							) : (
								<ImageBlock>
									<Link to={`project${project.fields.slug}`}>
										<DesktopImage src={project.frontmatter.thumbnail_1} />
									</Link>
								</ImageBlock>
							)}
						</ProjectPreview>
						{index + 1 !== projects.length && <HorizontalLine />}
					</Project>
				))}
			</ProjectsWrapper>
		</>
	)
}

const ProjectsWrapper = styled.div`
	@media (max-width: ${props => props.theme.sizes.minDesktopLarge}) {
		margin-left: -5vw;
		margin-right: -5vw;
	}
	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		margin-left: 0;
		margin-right: 0;
	}
`

const Project = styled.div``

const ProjectPreview = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex: 1 1 0px;
	margin-bottom: ${rhythm(1.5)};

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		flex-direction: column;
		align-items: center;
	}
`

const ProjectBlock = styled.div`
	height: 20rem;

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		height: unset;
		width: 100% !important;
	}
`

const ContentBlock = styled(ProjectBlock)`
	width: 30rem;
	padding-right: 2rem;

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		margin-bottom: 3rem;
	}
`

const ProjectType = styled(BigTitle)`
	display: block;
	font-size: ${rhythm(0.4)};
	margin-bottom: ${rhythm(0.5)};
`

const ProjectTitle = styled(Title)`
	margin-bottom: ${rhythm(0.5)};
	margin-left: -${rhythm(0.05)};
`

const ImageBlock = styled(ProjectBlock)`
	width: 35.56rem;
	/* max-width: 35.56rem; */
`
const TripleImagesBlock = styled(ImageBlock)`
	display: flex;
	justify-content: space-evenly;
`

const ProjectImg = styled.img`
	margin: 0;

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		border-radius: 15px;
	}
`

const DesktopImage = styled(ProjectImg)`
	width: 100%;
	border: solid 5px ${props => props.theme.colors.secondary};
	box-shadow: 1px 5px 5px 0px #0000004a;
	border-radius: 30px;
`

const MobileImage = styled(ProjectImg)` */
	max-width: 11rem;
	align-self: flex-start;

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		width: 30%;
	}
`

const HorizontalLine = styled.div`
	background-color: ${props => lighten(0.8, props.theme.colors.secondary)};

	margin-bottom: ${rhythm(1.5)};
	height: 1px;
`

// const NextRect = styled(Rect)`
// 	position: absolute;
// 	z-index: 90;
// 	width: 20rem;
// 	height: 30rem;
// 	margin-top: -10rem;
// 	right: -15rem;
// 	transform: rotate(10deg);

// 	:hover {
// 		transform: rotate(12deg);
// 		margin-right: 1rem;
// 	}

// 	transition: all 0.2s ease-in-out;
// 	cursor: pointer;

// 	@media (max-width: ${props => props.theme.sizes.minDesktopLarge}) {
// 		right: -18rem;
// 	}
// `

// const NextLabel = styled.div`
// 	position: absolute;
// 	display: flex;
// 	align-items: center;
// 	font-family: ${props => props.theme.fonts.big};
// 	color: ${props => props.theme.colors.primary};
// 	padding-right: ${rhythm(0.1)};
// 	padding-bottom: ${rhythm(0.5)};
// 	padding-left: ${rhythm(1)};
// 	bottom: 0;
// 	left: 0;
// `

// const AnimatedIconRight = styled.span`
// 	display: flex;
// 	align-items: center;
// 	animation: LeftRight 1s linear infinite;

// 	@keyframes LeftRight {
// 		0%,
// 		100% {
// 			margin-left: 0;
// 		}
// 		50% {
// 			margin-left: ${rhythm(0.2)};
// 		}
// 	}
// `

// const PreviousRect = styled(Rect)`
// 	position: absolute;
// 	z-index: 90;
// 	width: 20rem;
// 	height: 30rem;
// 	margin-top: 20rem;
// 	left: -15rem;
// 	transform: rotate(10deg);

// 	:hover {
// 		transform: rotate(12deg);
// 		margin-left: 1rem;
// 	}
// 	transition: all 0.2s ease-in-out;
// 	cursor: pointer;

// 	@media (max-width: ${props => props.theme.sizes.minDesktopLarge}) {
// 		left: -18rem;
// 	}
// `

// const PreviousLabel = styled.div`
// 	position: absolute;
// 	display: flex;
// 	align-items: center;
// 	font-family: ${props => props.theme.fonts.big};
// 	color: ${props => props.theme.colors.primary};
// 	padding-right: ${rhythm(0.1)};
// 	padding-top: ${rhythm(0.5)};
// 	padding-right: ${rhythm(1)};
// 	top: 0;
// 	right: 0;
// `

// const AnimatedIconLeft = styled.span`
// 	display: flex;
// 	align-items: center;
// 	animation: RightLeft 1s linear infinite;

// 	@keyframes RightLeft {
// 		0%,
// 		100% {
// 			margin-right: 0;
// 		}
// 		50% {
// 			margin-right: ${rhythm(0.2)};
// 		}
// 	}
// `
